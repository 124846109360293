import {
	Auth,
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	setPersistence,
	browserSessionPersistence,
	updateProfile,
	EmailAuthProvider,
	GoogleAuthProvider,
	signInWithPopup,
	sendPasswordResetEmail,
	updatePassword,
	linkWithCredential,
	fetchSignInMethodsForEmail,
	UserCredential,
	getAdditionalUserInfo,
} from 'firebase/auth';
import { app } from '../utils/general';
import { FirebaseError } from 'firebase/app';

export class AuthService {
	private auth: Auth;
	private googleAuthProvider: GoogleAuthProvider;

	constructor() {
		this.auth = getAuth(app);

		this.auth.languageCode = document.documentElement.lang || 'it';

		this.googleAuthProvider = new GoogleAuthProvider();

		this.checkAuth();
	}

	private async registerUser(): Promise<boolean> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		const idToken = await this.auth.currentUser.getIdToken();
		const response = await fetch('/api/auth/registerUser.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (!response.ok) {
			throw new Error('Failed to save user in MySQL');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to save user in MySQL');
		}

		return data.success;
	}

	private async addToNewsletter(): Promise<boolean> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		const idToken = await this.auth.currentUser.getIdToken();
		const response = await fetch('/api/newsletter/addSubscriber.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({
				userId: this.auth.currentUser.uid,
				email: this.auth.currentUser.email,
				phone: this.auth.currentUser.phoneNumber,
			}),
		});

		if (!response.ok) {
			throw new Error('Failed to add user to newsletter');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to add user to newsletter');
		}

		return data;
	}

	private async removeFromNewsletter(): Promise<boolean> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		const idToken = await this.auth.currentUser.getIdToken();
		const response = await fetch('/api/newsletter/removeSubscriber.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({
				userId: this.auth.currentUser.uid,
				email: this.auth.currentUser.email,
			}),
		});

		if (!response.ok) {
			throw new Error('Failed to remove user from newsletter');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to remove user from newsletter');
		}

		return data;
	}

	private async loginUser(): Promise<boolean> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		const idToken = await this.auth.currentUser.getIdToken();
		const response = await fetch('/api/auth/loginUser.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (!response.ok) {
			throw new Error('Failed to login user');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to login user');
		}

		return data.loginExpired;
	}

	private async logoutUser(): Promise<boolean> {
		const response = await fetch('/api/auth/logoutUser.php');

		if (!response.ok) {
			throw new Error('Failed to logout user');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to logout user');
		}

		return data.wasLoggedIn;
	}

	private async sendWelcomeEmail(): Promise<void> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		const idToken = await this.auth.currentUser.getIdToken();
		const response = await fetch('/api/auth/sendWelcomeEmail.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({
				lang: this.auth.languageCode,
			}),
		});

		if (!response.ok) {
			throw new Error('Failed to send welcome email');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to send welcome email');
		}
	}

	private async checkAuth(): Promise<void> {
		onAuthStateChanged(this.auth, async (user) => {
			// if page is register then do nothing
			if (user) {
				try {
					if (window.location.pathname === '/registrati.html') {
						return;
					}

					const shouldReload = await this.loginUser();

					if (shouldReload) {
						window.location.reload();
					}
				} catch (error) {
					console.error(error);
				}
			} else {
				try {
					const shouldReload = await this.logoutUser();

					if (shouldReload) {
						window.location.reload();
					}
				} catch (error) {
					console.error(error);
				}
			}
		});
	}

	public async updateUserDisplayName(displayName: string): Promise<void> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		await updateProfile(this.auth.currentUser, {
			displayName: displayName,
		});

		await this.auth.currentUser.getIdToken(true);

		await this.loginUser();
	}

	public async updateUserPassword(currentPassword: string, newPassword: string): Promise<void> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		try {
			await signInWithEmailAndPassword(
				this.auth,
				this.auth.currentUser.email,
				currentPassword,
			);
		} catch (error) {
			if (error.code === 'auth/wrong-password') {
				throw new FirebaseError('auth/wrong-password', 'Invalid password');
			}

			throw error;
		}

		await updatePassword(this.auth.currentUser, newPassword);

		await this.auth.currentUser.getIdToken(true);
	}

	public async signUp(
		email: string,
		password: string,
		displayName: string,
	): Promise<boolean | string[]> {
		const signInMethods = await fetchSignInMethodsForEmail(this.auth, email);
		if (signInMethods.length === 0) {
			await createUserWithEmailAndPassword(this.auth, email, password);
			if (!this.auth.currentUser) {
				throw new Error('Failed to create user');
			}

			await this.updateUserDisplayName(displayName);
			await this.sendWelcomeEmail();
			return await this.registerUser();
		} else {
			if (signInMethods.includes('password')) {
				throw new FirebaseError('auth/email-already-in-use', 'Email already in use');
			}

			return signInMethods;
		}
	}

	public async linkWithExistingProvider(
		provider: GoogleAuthProvider,
		email: string,
		password: string,
		displayName: string,
	): Promise<boolean> {
		await signInWithPopup(this.auth, provider);

		if (!this.auth.currentUser || this.auth.currentUser.email !== email) {
			throw new FirebaseError('auth/internal-error', 'Failed to link account');
		}

		const credential = EmailAuthProvider.credential(email, password);
		await linkWithCredential(this.auth.currentUser, credential);
		await this.updateUserDisplayName(displayName);
		return await this.registerUser();
	}

	public async signIn(email: string, password: string): Promise<void> {
		await signInWithEmailAndPassword(this.auth, email, password);
		await this.loginUser();
		document.cookie = 'userLoggedIn=true; path=/';
	}

	public async resetPassword(email: string): Promise<void> {
		return await sendPasswordResetEmail(this.auth, email);
	}

	public async signInWithGoogle(): Promise<void> {
		await signInWithPopup(this.auth, this.googleAuthProvider)
			.then(async (result: UserCredential) => {
				const additionalUserInfo = getAdditionalUserInfo(result);

				if (additionalUserInfo?.isNewUser) {
					await this.sendWelcomeEmail();
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(async () => {
				await this.registerUser();
				document.cookie = 'userLoggedIn=true; path=/';
			});
	}

	public async setPersistenceToBrowserSession(): Promise<void> {
		await setPersistence(this.auth, browserSessionPersistence);
	}

	public signOut(): void {
		this.auth.signOut();
	}

	public isLoggedIn(): boolean {
		return this.auth.currentUser !== null;
	}

	public getProviderFromMap(providerId: string): GoogleAuthProvider {
		switch (providerId) {
			case 'google.com':
				return this.googleAuthProvider;
			default:
				throw new Error('Invalid provider');
		}
	}

	public async subscribeToNewsletter(): Promise<void> {
		await this.addToNewsletter();
	}

	public async unsubscribeFromNewsletter(): Promise<void> {
		await this.removeFromNewsletter();
	}

	public getIdToken(): Promise<string> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		return this.auth.currentUser.getIdToken();
	}

	public getUid(): string {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		return this.auth.currentUser.uid;
	}
}
