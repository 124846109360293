import * as m from '../../../../../src/paraglide/messages';
import { AbstractFormHandler } from './abstractFormHandler';
import { addressSchema, checkoutSchema, contactSchema } from '../../utils/validation';
import { ZodError } from 'zod';
import {
	Address,
	AddressWithContact,
	CheckoutData,
	Contact,
} from '../../models/interfaces/general';
import { AddressType, ContactType, PaymentMethod } from '../../models/enums/general';
import { FormDataWrapper } from '../../utils/FormDataWrapper';
import { AuthService } from '../auth';
import { NewsletterService } from '../newsletter';

export class CheckoutFormHandler extends AbstractFormHandler {
	private authService: AuthService;
	private newsletterService: NewsletterService;

	constructor(authService: AuthService, newsletterService: NewsletterService) {
		super();
		this.authService = authService;
		this.newsletterService = newsletterService;
	}

	public async submitForm(event: Event): Promise<void> {
		event.preventDefault();

		const form = event.target as HTMLFormElement;
		this.removeAlerts(form);
		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);
		const { checkoutData, billingAddressWithContact, shippingAddressWithContact } =
			this.getFormData(form);
		const shippingOptions = this.getShippingOptions(form);

		try {
			const zodError: ZodError = await this.superValidate(
				{
					schema: checkoutSchema,
					data: { ...checkoutData, company: billingAddressWithContact.contact.company },
				},
				{
					schema: addressSchema,
					data: billingAddressWithContact.address,
					prefix: AddressType.Billing,
				},
				{
					schema: addressSchema,
					data: shippingAddressWithContact.address,
					prefix: AddressType.Shipping,
				},
				{
					schema: contactSchema,
					data: billingAddressWithContact.contact,
					prefix: ContactType.Billing,
				},
				{
					schema: contactSchema,
					data: shippingAddressWithContact.contact,
					prefix: ContactType.Shipping,
				},
			);

			if (zodError.issues.length > 0) {
				throw zodError;
			}

			const termsAndConditions = form.querySelector(
				'#terms-and-conditions',
			) as HTMLInputElement;
			if (!termsAndConditions.checked) {
				throw new Error(m.terms_and_conditions_not_accepted());
			}

			const newsletter = form.querySelector('#newsletter') as null | HTMLInputElement;

			if (newsletter && newsletter.checked) {
				try {
					if (this.authService.isLoggedIn()) {
						await this.authService.subscribeToNewsletter();
					} else {
						await this.newsletterService.subscribeToNewsletter(checkoutData.email);
					}
				} catch (error) {
					console.error('Error subscribing to newsletter: ', error);
				}
			}

			const responseData = await this.submitData(
				checkoutData,
				billingAddressWithContact,
				shippingAddressWithContact,
				shippingOptions,
			);

			if (!responseData.success) {
				throw new Error(responseData.message);
			}

			if (responseData.redirect) {
				window.location.href = responseData.redirect;
				return;
			}

			this.displaySuccessMessage(form, responseData.message);
		} catch (error) {
			if (error instanceof ZodError) {
				this.createAlertForEachInput(form, error);
				form.scrollIntoView({ behavior: 'smooth', block: 'start' });
			} else {
				this.displayErrorMessage(form, this.extractErrorMessage(error));
			}
		} finally {
			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	private getFormField(
		key: string,
		formDataWrapper: FormDataWrapper,
		prefix: string,
		nullable: boolean = false,
	): string {
		const value = formDataWrapper.getNullableString(`${prefix}_${key}`);
		return nullable ? value : (value ?? '');
	}

	private getFormData(form: HTMLFormElement): {
		checkoutData: CheckoutData;
		billingAddressWithContact: AddressWithContact;
		shippingAddressWithContact: AddressWithContact;
	} {
		const formDataWrapper = new FormDataWrapper(form);

		let paymentMethod = formDataWrapper.getString('payment_method');
		if (paymentMethod === 'bank-transfer') {
			paymentMethod = PaymentMethod.BankTransfer;
		} else if (paymentMethod === 'credit-card') {
			paymentMethod = PaymentMethod.CreditCard;
		}

		const checkoutData: CheckoutData = {
			certified_id: formDataWrapper.getNullableString('certified_id'),
			vat_number: formDataWrapper.getNullableString('vat_number'),
			fiscal_code: formDataWrapper.getNullableString('fiscal_code'),
			email: formDataWrapper.getString('email'),
			order_notes: formDataWrapper.getNullableString('order_notes'),
			payment_method: paymentMethod,
		};

		const billingAddress: Address = {
			address1: this.getFormField('address1', formDataWrapper, AddressType.Billing),
			address2: this.getFormField('address2', formDataWrapper, AddressType.Billing, true),
			address3: null,
			state: this.getFormField('state', formDataWrapper, AddressType.Billing),
			city: this.getFormField('city', formDataWrapper, AddressType.Billing),
			postal_code: this.getFormField('postal_code', formDataWrapper, AddressType.Billing),
			address_type: AddressType.Billing,
		};

		const billingContact: Contact = {
			first_name: this.getFormField('first_name', formDataWrapper, ContactType.Billing),
			last_name: this.getFormField('last_name', formDataWrapper, ContactType.Billing),
			company: this.getFormField('company', formDataWrapper, ContactType.Billing, true),
			email: null,
			phone: this.getFormField('phone', formDataWrapper, ContactType.Billing),
			contact_type: ContactType.Billing,
		};

		const billingAddressWithContact: AddressWithContact = {
			address: billingAddress,
			contact: billingContact,
		};

		const shippingToggle = form.querySelector('#shipping-toggle') as HTMLInputElement;
		const shippingToggled =
			shippingToggle.checked || shippingToggle.classList.contains('checked') || false;

		const shippingAddressType = shippingToggled ? AddressType.Shipping : AddressType.Billing;
		const shippingContactType = shippingToggled ? ContactType.Shipping : ContactType.Billing;

		const shippingAddress: Address = {
			address1: this.getFormField('address1', formDataWrapper, shippingAddressType),
			address2: this.getFormField('address2', formDataWrapper, shippingAddressType, true),
			address3: null,
			state: this.getFormField('state', formDataWrapper, shippingAddressType),
			city: this.getFormField('city', formDataWrapper, shippingAddressType),
			postal_code: this.getFormField('postal_code', formDataWrapper, shippingAddressType),
			address_type: AddressType.Shipping,
		};

		const shippingContact: Contact = {
			first_name: this.getFormField('first_name', formDataWrapper, shippingContactType),
			last_name: this.getFormField('last_name', formDataWrapper, shippingContactType),
			company: this.getFormField('company', formDataWrapper, shippingContactType, true),
			email: null,
			phone: this.getFormField('phone', formDataWrapper, shippingContactType),
			contact_type: ContactType.Shipping,
		};

		const shippingAddressWithContact: AddressWithContact = {
			address: shippingAddress,
			contact: shippingContact,
		};

		return { checkoutData, billingAddressWithContact, shippingAddressWithContact };
	}

	private getShippingOptions(form: HTMLFormElement): Record<string, string> {
		const shippingOptions = form.querySelectorAll('input[name^="shipping-seller-"]:checked');
		const selectedOptions: Record<string, string> = {};

		shippingOptions.forEach((option) => {
			if (!(option instanceof HTMLInputElement) || !option.hasAttribute('data-seller')) {
				console.error('Invalid shipping option element', option);
				return;
			}

			const sellerId = option.getAttribute('data-seller') as string;
			selectedOptions[sellerId] = option.value;
		});

		return selectedOptions;
	}

	private async submitData(
		checkoutData: CheckoutData,
		billingAddressWithContact: AddressWithContact,
		shippingAddressWithContact: AddressWithContact,
		shippingOptions: Record<string, string>,
	): Promise<{ redirect: string; success: boolean; message: string }> {
		const response = await fetch('/api/order/create.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				checkoutData,
				billingAddressWithContact,
				shippingAddressWithContact,
				shippingOptions,
			}),
		});

		return response.json();
	}
}
