
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_credentials = () => `L'email o la password che hai inserito non sono corrette`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_email = () => `L'Email che hai inserito non è valida`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_user_disabled = () => `Questo account è stato disabilitato`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_too_many_requests = () => `L'accesso a questo account è stato temporaneamente disabilitato a causa di molti tentativi di accesso falliti. Puoi ripristinarlo immediatamente reimpostando la tua password o puoi riprovare più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unknown_error = () => `Si è verificato un errore sconosciuto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_error_heading = () => `Sembra che ci sia stato un errore.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_not_found = () => `Utente non trovato`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_email_already_in_use = () => `Questo indirizzo email è già associato a un account. Ti sei già registrato? Prova ad accedere o utilizza un altro indirizzo email.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_email = () => `L'indirizzo email non è valido`




	
/**
 * @param {{ label: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_string = (params) => `Il campo ${params.label} non è valido`




	
/**
 * @param {{ label: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const too_big = (params) => `Il campo ${params.label} è troppo corto`




	
/**
 * @param {{ label: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const too_small = (params) => `Il campo ${params.label} è troppo corto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_privacy = () => `Il campo Privacy è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const email_label = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const password_label = () => `Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const name_label = () => `Nome`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const surname_label = () => `Cognome`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_label = () => `Privacy`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_error_unknown = () => `Oops! Si è verificato un errore durante il login. Per favore, riprova più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_name = () => `Il campo 'Nome' non può essere vuoto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_surname = () => `Il campo 'Cognome' non può essere vuoto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_password = () => `La password è troppo corta (minimo 6 caratteri)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tax_id_required = () => `Il campo P.IVA / Codice Fiscale è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tax_id_too_long = () => `Il campo P.IVA / Codice Fiscale deve essere lungo al massimo 20 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const phone_required = () => `Il campo Telefono è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_required = () => `Il campo Indirizzo è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const state_required = () => `Il campo Provincia è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const city_required = () => `Il campo Città è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const postal_code_required = () => `Il campo CAP è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const name_too_long = () => `Il campo nome deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const surname_too_long = () => `Il campo cognome deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const phone_too_long = () => `Il campo telefono deve contenere al massimo 25 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const email_too_long = () => `Il campo email deve contenere al massimo 50 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_too_long = () => `Il campo indirizzo deve contenere al massimo 255 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const state_too_long = () => `Il campo stato deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const city_too_long = () => `Il campo città deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const postal_code_too_long = () => `Il campo CAP deve contenere al massimo 20 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const company_too_long = () => `Il campo Azienda è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_size_required = () => `Il campo Tipo del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_size_too_long = () => `Il campo Tipo del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_name_required = () => `Il campo Nome del negozio deve avere tra 2 e 30 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_name_too_long = () => `Il campo Nome del negozio deve avere tra 2 e 30 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_address_required = () => `Il campo Indirizzo del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_address_too_long = () => `Il campo Indirizzo del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_state_required = () => `Il campo Provincia del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_state_too_long = () => `Il campo Provincia del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_city_required = () => `Il campo Città del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_city_too_long = () => `Il campo Città del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_postal_code_required = () => `Il campo CAP del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_postal_code_too_long = () => `Il campo CAP del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_motivation_too_long = () => `Il campo Motivazione è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_email_invalid = () => `Il campo Email del negozio non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_email_too_long = () => `Il campo Email del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_logo_field_name = () => `Il logo del negozio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_banner_field_name = () => `Il banner del negozio`




	
/**
 * @param {{ fieldName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const field_name_not_an_image = (params) => `${params.fieldName} non è un'immagine`




	
/**
 * @param {{ fieldName: NonNullable<unknown>, maxUploadSize: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const field_name_file_too_big = (params) => `${params.fieldName} è troppo grande massimo ${params.maxUploadSize} Mb`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_phone_required = () => `Il campo telefono è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_phone_too_long = () => `Il campo telefono è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const reset_password_success = () => `Il link per il reset della password è stato inviato al tuo indirizzo email. Controlla la tua casella di posta.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const password_mismatch = () => `Il campo "Nuova password" e "Conferma password" devono corrispondere`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_details_update_success = () => `I tuoi dettagli sono stati aggiornati con successo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_link_cancelled = () => `Il processo di registrazione è stato annullato dall'utente. Si prega di riprovare.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certified_id_too_long = () => `Il campo Pec / Sdi è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certified_id_required = () => `'Il campo Pec / SDI è obbligatorio',`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_wrong_password = () => `La password che hai inserito non è corretta.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_network_request_failed = () => `Si è verificato un problema di connessione. Riprova.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_password_weak = () => `La password che hai inserito è troppo debole. Scegline una più sicura.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_operation_not_allowed = () => `Questa operazione non è consentita. Contatta il supporto.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_requires_recent_login = () => `Per favore, accedi di nuovo per completare questa operazione.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_account_exists_with_different_credential = () => `Esiste già un account con queste credenziali. Accedi con un altro metodo.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_credential_already_in_use = () => `Questa credenziale è già utilizzata da un altro account.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_verification_code = () => `Il codice di verifica inserito non è valido. Riprova.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_missing_email = () => `Per favore, inserisci un'email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_verification_id = () => `L'ID di verifica inserito non è valido. Riprova.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_quota_exceeded = () => `Hai superato il limite di richieste. Riprova più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_required = () => `Il campo Paese è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_too_long = () => `Il campo Paese deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const day_required = () => `Il campo Giorno è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const day_invalid = () => `Il campo Giorno non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const month_required = () => `Il campo Mese è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const month_invalid = () => `Il campo Mese non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const year_required = () => `Il campo Anno è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const year_invalid = () => `Il campo Anno non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_date = () => `La data inserita non è valida`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_seller_type = () => `Il tipo di venditore selezionato non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civic_number_required = () => `Il campo Numero civico è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civic_number_too_long = () => `Il campo Numero civico deve contenere al massimo 20 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bank_account_holder_required = () => `Il campo Titolare del conto bancario è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bank_account_holder_too_long = () => `Il campo Titolare del conto bancario deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bic_swift_invalid = () => `Il codice BIC/SWIFT inserito non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const iban_invalid = () => `L'IBAN inserito non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const date_required = () => `La data di nascita è obbligatoria`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_and_conditions_not_accepted = () => `Devi accettare i termini e le condizioni per procedere con l'ordine.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unsubscribe_from_newsletter_failed = () => `Ops! Qualcosa è andato storto durante la cancellazione dalla newsletter. Per favore, riprova più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unknown_error_processing_request = () => `Si è verificato un errore sconosciuto durante l'elaborazione della richiesta. Per favore, riprova più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const payment_endpoint_unreachable = () => `Impossibile raggiungere il server per l'elaborazione del pagamento. Controlla la tua connessione e riprova.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const payment_process_failed = () => `Il processo di pagamento non è riuscito. Per favore, riprova più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const payment_redirect_missing = () => `L'URL di reindirizzamento per il pagamento non è disponibile. Contatta il supporto.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const payment_form_missing = () => `Si è verificato un errore durante la preparazione del pagamento. Riprova più tardi o contatta il supporto.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fiscal_code_too_long = () => `Il campo Codice Fiscale è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fiscal_code_required = () => `Il campo Codice Fiscale è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fiscal_code_invalid = () => `Il campo Codice Fiscale non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vat_number_too_long = () => `Il campo Partita IVA è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vat_number_required = () => `Il campo Partita IVA è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vat_number_invalid = () => `Il campo Partita IVA non è valido`

