import { AuthService } from './auth';
import { AuthHandler } from './handlers/authHandler';
import { CheckoutFormHandler } from './handlers/checkoutFormHandler';
import { InfoSellerFormHandler } from './handlers/infoSellerFormHandler';
import { PaymentHandler } from './handlers/paymentHandler';
import { RegisterSellerFormHandler } from './handlers/registerSellerFormHandler';
import { UserAddressFormHandler } from './handlers/userAdressFormHandler';
import { NewsletterService } from './newsletter';

const authService = new AuthService();
const newsletterService = new NewsletterService();

export function initializeDOMEvents(): void {
	const authHandler = new AuthHandler(authService);
	const paymentHandler = new PaymentHandler();
	const checkoutFormHandler = new CheckoutFormHandler(authService, newsletterService);
	const registerSellerFormHandler = new RegisterSellerFormHandler();
	const infoSellerFormHandler = new InfoSellerFormHandler(paymentHandler);
	const userAddressFormHandler = new UserAddressFormHandler(authService);

	//signIn events
	document.addEventListener('signIn', (event: Event) =>
		authHandler.loginFormSubmitHandler(event as CustomEvent),
	);
	document.addEventListener('resetPassword', (event: Event) =>
		authHandler.resetPasswordHandler(event as CustomEvent),
	);

	document.addEventListener('signInWithGoogle', (event: Event) =>
		authHandler.loginWithGoogleHandler(event as CustomEvent),
	);

	document.addEventListener('signUp', (event: Event) =>
		authHandler.signUpFormSubmitHandler(event as CustomEvent),
	);
	document.addEventListener('signOut', () => authService.signOut());

	document.addEventListener('updateAccountDetails', (event: Event) =>
		authHandler.updateAccountDetailsHandler(event as CustomEvent),
	);

	document.addEventListener('newsletterUnsubscribe', () => {
		authHandler.unsubscribeFromNewsletter();
	});

	const checkoutForm = document.querySelector('.checkout-form') as HTMLFormElement;
	checkoutForm?.addEventListener('submit', (event) => {
		event.preventDefault();
		checkoutFormHandler.submitForm(event);
	});

	const registerSellerForm = document.querySelector('.register-seller-form') as HTMLFormElement;
	registerSellerForm?.addEventListener('submit', (event) => {
		event.preventDefault();
		registerSellerFormHandler.submitForm(event);
	});

	const infoSellerForm = document.querySelector('.info-seller-form') as HTMLFormElement;
	infoSellerForm?.addEventListener('submit', (event) => {
		event.preventDefault();
		infoSellerFormHandler.submitForm(event);
	});

	const updateBillingAddressForm = document.getElementById(
		'update-billing-address-form',
	) as HTMLFormElement;
	updateBillingAddressForm?.addEventListener('submit', (event) => {
		event.preventDefault();
		userAddressFormHandler.submitForm(event);
	});

	const updateShippingAddressForm = document.getElementById(
		'update-shipping-address-form',
	) as HTMLFormElement;
	updateShippingAddressForm?.addEventListener('submit', (event) => {
		event.preventDefault();
		userAddressFormHandler.submitForm(event);
	});

	document.addEventListener('retrySellerPayment', async () => {
		try {
			const form = document.getElementById('seller-retry-payment-form') as HTMLFormElement;
			paymentHandler.setFormId('seller-retry-payment-form');
			await paymentHandler.triggerPayment();
			form.submit();
		} catch (error) {
			console.error(error);
		}
	});
}
